import React from "react";
import { Nav } from "react-bootstrap";
import Slider from "react-slick";
import wingsData from "../Assets/wings.json";

const Testimonial = () => {
  const { testimonialData } = wingsData;
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <section className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <Slider {...settings} className="slider-contents">
                {testimonialData[0].slide.map((slide, index) => (
                  <div key={index} className="slider-items-img">
                    <img src={slide.image} alt={`testimonial-${index + 1}`} />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="testimonial-text pt-lg-4 mt-lg-3">
                <div className="fs-36">{testimonialData[1].title}</div>
                <div className="fs-20 fw-medium">
                  {testimonialData[1].desc.split("\n").map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </div>

                <Nav.Link
                  href={testimonialData[1].url}
                  target="_black"
                  className="btn-lg btn-primary fs-16 fw-bold btn-text-black"
                >
                  Order on Amazon
                </Nav.Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

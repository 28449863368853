import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Page/Home";

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
      setTimeout(() => {
        document.documentElement.scrollTo(0, 0);
      }, 0);
    }, [location.pathname, location.search]);
    return children;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;

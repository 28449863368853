import React, { useState } from "react";
import { Dropdown, Image, Nav, Tab } from "react-bootstrap";
import wingsData from "../Assets/wings.json";

const Card = () => {
  const { wingsCard, sidesCard, combosCard } = wingsData;
  const [cards, setCards] = useState(wingsCard);

  const updateWingsCard = (id, price, quantity) => {
    const updatedCard = cards.map((card) => {
      if (card.id === id) {
        return { ...card, price, quantity };
      }
      return card;
    });
    setCards(updatedCard);
  };

  const renderCard = (cards) => {
    return cards.map((card, index) => (
      <div className="card-item col-lg-4 col-md-4 col-6" key={index}>
        <div className="card-content">
          <div className="card-img">
            <Image src={card.pick} alt={card.name} />
          </div>
          <div className="card-btn-text">
            <div className="fs-20 fw-semibold">{card.name}</div>
            <div className="price-btn">
              {Array.isArray(card.pcsPrice) ? (
                <Dropdown drop="down-centered" className="card-dropdown-btn">
                  <Dropdown.Toggle>
                    <div className="card-price-btn">
                      <span style={{ color: "#9B9B9B" }}>
                        {card.quantity} <span className="fs-9">PCS.</span>
                      </span>
                      {` $${card.price}`}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {card.pcsPrice
                      .filter((c) => c.pcs !== card.quantity)
                      .map((option, index) => (
                        <Dropdown.Item
                          key={index}
                          id={index.id}
                          href=""
                          onClick={() =>
                            updateWingsCard(card.id, option.price, option.pcs)
                          }
                          /* className={option === selectedOption ? "active" : ""} */
                        >
                          <div className="card-price-btn">
                            <span style={{ color: "#9B9B9B" }}>
                              {option.pcs} <span className="fs-9">PCS.</span>
                            </span>
                            {` $${option.price}`}
                          </div>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className="card-price-btn">
                  <span style={{ color: "#9B9B9B" }}>
                    {card.pcsPrice.pcs} <span className="fs-9">PCS.</span>
                  </span>
                  {` $${card.pcsPrice.price}`}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <section className="card-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Tab.Container defaultActiveKey="first" transition={false}>
                <Nav variant="pills" className="card-nav">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Wings</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Sides</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Combos</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  {/* Wings */}
                  <Tab.Pane eventKey="first">
                    <div className="card-row row">{renderCard(cards)}</div>
                  </Tab.Pane>
                  {/* Sides */}
                  <Tab.Pane eventKey="second">
                    <div className="card-row row">{renderCard(sidesCard)}</div>
                  </Tab.Pane>
                  {/* Combos */}
                  <Tab.Pane eventKey="third">
                    <div className="card-combos">{renderCard(combosCard)}</div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Card;

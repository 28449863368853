import React from "react";
import { Image, Nav } from "react-bootstrap";
import wingsData from "../Assets/wings.json";
import bannerVideo from "../Assets/Video/banner-video.mp4";
import { Link } from "react-router-dom";

const HeroSecon = () => {
  const { bannerSection } = wingsData;
  const { logo, title, delivered } = bannerSection[0];
  return (
    <>
      <section className="banner-area">
        <video src={bannerVideo} autoPlay loop muted></video>
        <div className="banner-content">
          <div className="banner-text-content">
            <Link to="">
              <Image src={logo} alt="Banner Image" className="banner-logo" />
            </Link>
            <h1>{title}</h1>
            <div className="d-flex align-items-center gap-3 deliver-text">
              <div className="fs-16 fw-medium">delivered by</div>
              <Link to="">
                <img src={delivered} alt="Delivered Logo" />
              </Link>
            </div>
            <Nav.Link
              target="_blank"
              href="https://www.heyjageats.com/products/category/646bf5f278638"
              className="btn-lg btn-secondary fs-20 fw-bold"
            >
              Order Now
            </Nav.Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSecon;

import { Image, Nav } from "react-bootstrap";
import HeroSecon from "../Components/HeroSecon";
import Card from "../Components/Card";
import wingsData from "../Assets/wings.json";
import { FiInstagram } from "react-icons/fi";
import { FaYoutube, FaTiktok } from "react-icons/fa6";
import Testimonial from "../Components/Testimonial";
import { Link } from "react-router-dom";

const Home = () => {
  const { mainLogo } = wingsData;
  return (
    <>
      <div className="header-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <Nav.Link href="">
                  <Image src={mainLogo} alt={mainLogo} />
                </Nav.Link>
                <Link
                  target="_blank"
                  to="https://www.heyjageats.com/products/category/646bf5f278638"
                  className="btn-md btn-36 btn-primary fs-16 fw-bold btn-text-black text-decoction-none"
                >
                  Order Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeroSecon />
      <Card />
      <Testimonial />
      <footer className="footer-wedged">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-6 align-self-center">
              <div className="text-center">
                <Nav.Link href="">
                  <Image src={mainLogo} alt={mainLogo} />
                </Nav.Link>
              </div>
            </div>
            <div className="col-lg-3 col-6 align-self-center">
              <ul className="address">
                <li>
                  <Nav.Link
                    target="_blank"
                    href="https://docs.google.com/document/d/1EbWbi4O5Ew6Ekd7ZU7WG1cv0nGQVSa63jMPD2O5mvj8/edit"
                  >
                    Terms and conditions
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    target="_blank"
                    href="https://docs.google.com/document/d/1EbWbi4O5Ew6Ekd7ZU7WG1cv0nGQVSa63jMPD2O5mvj8/edit"
                  >
                    Privacy policy
                  </Nav.Link>
                </li>
                <li>+1 561 536-5400</li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 align-self-center">
              <div className="footer-social">
                <Nav.Link
                  target="_blank"
                  href="https://www.instagram.com/justchickenwings_fl/"
                >
                  <FiInstagram />
                </Nav.Link>
                <Nav.Link
                  target="_blank"
                  href="https://www.youtube.com/@justchickenwings8158"
                >
                  <FaYoutube />
                </Nav.Link>
                <Nav.Link
                  target="_blank"
                  href="https://www.tiktok.com/@justchickenwings_fl"
                >
                  <FaTiktok />
                </Nav.Link>
              </div>
            </div>
            <div className="col-lg-3 col-6 align-self-center">
              <div className="footer-btn">
              <Nav.Link
  className="btn-md btn-36 btn-primary fs-12 fw-bold btn-text-black"
  onClick={() => {
    if (/android/i.test(navigator.userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.heyjageats.customer&pli=1';
    } else if (/macbook|iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/ae/app/hey-jag/id6447595812';
    } else {
      // This else part is optional, for devices that are neither Android nor iOS, like PCs.
      alert('Please visit this page on an Android or iOS device to download the app');
    }
  }}
>
  Download Hey! JAG App
</Nav.Link>

                <Nav.Link
                  className="btn-md btn-36 btn-primary fs-12 fw-bold btn-text-black"
                  href="https://www.simplevirtualkitchen.com/"
                  target="_blank"
                >
                  Become a Partner
                </Nav.Link>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="copyright text-center ">
                <div className="fs-12">
                  Copyright ⓒ 2024 Just Chicken Wings. All right reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home;
